import React, { useEffect, useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Seo from "../components/seo";
import { Helmet } from "react-helmet";
import Nav from "../components/Nav/Nav";
import * as ACFModules from "../modules/Modules";
import Fallback from "../modules/Fallback";
import PageFooter from "../modules/PageFooter/PageFooter";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { register } from "swiper/element/bundle";

const ModulePages = ({ data: { wpMenu, wpPage }, exit, transitionStatus }) => {
  const { title, template, slug, isFrontPage, seo } = wpPage;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [pageState, setPageState] = useState(null);
  const leftMenuItem = wpMenu.menuItems.nodes[0] || null;
  const rightMenuItem = wpMenu.menuItems.nodes[1] || null;
  const {
    pageModules: { modules },
  } = template;
  const pageTitle = title === "Home" ? "" : title + " -";

  const pageSlug = slug === "home" ? "" : slug;
  // const hasHero = modules?.find(
  //   (module) =>
  //     module.fieldGroupName === "Template_Modules_Pagemodules_Modules_Intro"
  // );

  const redirects = [
    { from: "purpose", to: "/studio" },
    { from: "brand-digital-studio", to: "/studio" },
  ];

  const redirect = redirects.find((redirect) => redirect.from === pageSlug);

  if (redirect) {
    // navigate(redirect.to);
  }

  register();

  useEffect(() => {
    sessionStorage.removeItem("selectedFilter");
    sessionStorage.removeItem("selectedList");
  }, []);

  useEffect(() => {
    window.scroll.update();

    // let timeout = setTimeout(() => {
    //   window.scroll.update();
    // }, 500);

    return () => {
      // clearTimeout(timeout);
    };
  }, [pageState]);

  function wrapFirstLetter(str) {
    return `<span>${str.charAt(0)}</span><span>${str.slice(1)}</span>`;
  }

  function closePopup() {
    const closeBtn = document.querySelector(".popup-content__close");

    if (closeBtn) {
      closeBtn.click();
    }
  }

  return (
    <>
      <Seo seo={seo} />
      {slug === "home" ? (
        <div className="intro-headings">
          <div className="intro-headings__name">
            <div>
              <h1>Seesaw</h1>
            </div>
            {leftMenuItem && (
              <div>
                <span>
                  <Link
                    cover
                    duration={1.5}
                    direction="right"
                    bg="#f2f3f0"
                    to={leftMenuItem.uri}
                    className={
                      pageState === "studio"
                        ? "active"
                        : !pageState
                        ? "init"
                        : ""
                    }
                  >
                    <h1>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: wrapFirstLetter(leftMenuItem.label),
                        }}
                      />
                    </h1>
                  </Link>
                  <svg
                    className="close-button"
                    style={{
                      opacity: isPopupOpen && pageState === "studio" ? 1 : 0,
                    }}
                    onClick={closePopup}
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="15.5566"
                      y="2.39468"
                      width="20"
                      height="20"
                      rx="10"
                      transform="rotate(45 15.5566 2.39468)"
                      stroke="black"
                      strokeWidth="2"
                    />
                    <line
                      x1="19.746"
                      y1="12.3927"
                      x2="11.4123"
                      y2="20.7264"
                      stroke="black"
                      strokeWidth="2"
                    />
                    <line
                      x1="19.7896"
                      y1="20.6383"
                      x2="11.4558"
                      y2="12.3045"
                      stroke="black"
                      strokeWidth="2"
                    />
                  </svg>
                </span>
              </div>
            )}
          </div>

          <div className="intro-headings__links">
            <div>
              {rightMenuItem && (
                <Link
                  cover
                  duration={1.5}
                  direction="right"
                  bg="#f2f3f0"
                  to={rightMenuItem.uri}
                  className={
                    pageState === "stories"
                      ? "active"
                      : !pageState
                      ? "init"
                      : ""
                  }
                >
                  <h1>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: wrapFirstLetter(rightMenuItem.label),
                      }}
                    />
                    <svg
                      width="32"
                      height="33"
                      viewBox="0 0 32 33"
                      fill="none"
                      className="close-button"
                      style={{
                        opacity: isPopupOpen && pageState === "studio" ? 1 : 0,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="15.5566"
                        y="2.39468"
                        width="20"
                        height="20"
                        rx="10"
                        transform="rotate(45 15.5566 2.39468)"
                        stroke="black"
                        strokeWidth="2"
                      />
                      <line
                        x1="19.746"
                        y1="12.3927"
                        x2="11.4123"
                        y2="20.7264"
                        stroke="black"
                        strokeWidth="2"
                      />
                      <line
                        x1="19.7896"
                        y1="20.6383"
                        x2="11.4558"
                        y2="12.3045"
                        stroke="black"
                        strokeWidth="2"
                      />
                    </svg>
                  </h1>
                </Link>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <main className={`modules${isFrontPage ? " is-front-page" : ""}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle} Seesaw Studio</title>
        </Helmet>
        <Nav slug={pageSlug} />
        {modules &&
          modules.map((module, id) => {
            if (Object.keys(module).length > 0) {
              const moduleName = module.fieldGroupName.replace(
                "Template_Modules_Pagemodules_Modules_",
                ""
              );
              const Component = ACFModules[moduleName] || Fallback;
              if (moduleName === "Intro" || moduleName.includes("Split")) {
                return (
                  <Component
                    key={id}
                    index={id}
                    {...module}
                    pageLocation={slug}
                    pageState={pageState}
                    setPageState={setPageState}
                    isPopupOpen={isPopupOpen}
                    setIsPopupOpen={setIsPopupOpen}
                  />
                );
              }
              return <Component key={id} {...module} pageLocation={slug} />;
            } else {
              return <></>;
            }
          })}

        {pageState === "studio" || pageState === "stories" ? (
          <PageFooter
            pageState={pageState}
            slug={pageState === "studio" ? "/stories" : "/"}
            label={pageState === "studio" ? "Stories" : "Studio"}
          />
        ) : (
          ""
        )}
      </main>
    </>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpMenu(name: { eq: "Navigation" }) {
      menuItems {
        nodes {
          label
          uri
        }
      }
    }

    wpPage(id: { eq: $id }) {
      title
      slug
      isFrontPage
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
      template {
        ... on WpTemplate_Modules {
          pageModules {
            modules {
              ... on WpTemplate_Modules_Pagemodules_Modules_Intro {
                fieldGroupName
                textLeft
                textRight
                imageLeft {
                  publicUrl
                  mediaItemUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }
                imageRight {
                  publicUrl
                  mediaItemUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }

                featuredProjects {
                  ... on WpProject {
                    title
                    uri
                    excerpt
                    featuredImage {
                      node {
                        publicUrl
                        mediaItemUrl
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(placeholder: DOMINANT_COLOR)
                          }
                        }
                        altText
                      }
                    }
                    landscapeImage {
                      image {
                        publicUrl
                        mediaItemUrl
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(placeholder: DOMINANT_COLOR)
                          }
                        }
                        altText
                      }
                    }
                    projectVideos {
                      videoThumbnailLandscape
                    }
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_SplitInfo {
                fieldGroupName
                text
                textRight
                contentLayout
                image {
                  publicUrl
                  mediaItemUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }
                info {
                  heading
                  text
                  imageLayout
                  imageLeft {
                    publicUrl
                    mediaItemUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                    altText
                  }
                  imageRight {
                    publicUrl
                    mediaItemUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                    altText
                  }
                  logo {
                    publicUrl
                    mediaItemUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                    altText
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_SplitTeam {
                fieldGroupName
                text
                textRight
                teamMembers {
                  fullName
                  role
                  text
                  portrait {
                    publicUrl
                    mediaItemUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                      }
                    }
                    altText
                  }
                }
                defaultImage {
                  publicUrl
                  mediaItemUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        layout: FULL_WIDTH
                      )
                    }
                  }
                  altText
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_SplitClients {
                fieldGroupName
                text
                image {
                  publicUrl
                  mediaItemUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }

                heading
                listHeading
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_Hero {
                fieldGroupName
                textLeft
                textRight
                images {
                  altText
                  publicUrl
                  caption
                  sourceUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        breakpoints: [750, 1080, 1366, 1920, 2560, 3000]
                      )
                    }
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_Content {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                backgroundColour
                heading
                text1
                text2
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TextAndImage {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                backgroundColour
                layout
                heading
                text1
                text2
                image {
                  publicUrl
                  mediaItemUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TextWithImageLayers {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                layout
                heading
                text1
                text2
                backgroundColour
                media {
                  type
                  video {
                    mediaItemUrl
                  }
                  image {
                    altText
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                      }
                    }
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_ProjectHero {
                fieldGroupName
                backgroundColour
                heading
                content
                content2
                image {
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        quality: 100
                        breakpoints: [750, 1080, 1366, 1920, 2560, 3000]
                      )
                    }
                  }
                  altText
                }
                accordionList {
                  info
                  label
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TwoColumnImages {
                fieldGroupName
                padding {
                  paddingBottomMobile
                  paddingTopMobile
                }
                layout
                mobileLayout
                backgroundColour
                leftImage {
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }
                rightImage {
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }
                videoLeft
                videoRight
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TwoPortraitImages {
                fieldGroupName
                mobileType
                backgroundColour
                slides {
                  leftVideo
                  rightVideo
                  leftImage {
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                      }
                    }
                    altText
                  }
                  rightImage {
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                      }
                    }
                    altText
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_SingleMedia {
                fieldGroupName
                enablePadding
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                mediaType
                disableParallaxEffect
                insetAnimation
                backgroundColour
                images {
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        layout: FULL_WIDTH
                        quality: 100
                        breakpoints: [750, 1080, 1366, 1920, 2560, 3000]
                      )
                    }
                  }
                  altText
                }
                video {
                  mediaItemUrl
                }
                videoUrl
                link
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_ScrollingScreens {
                fieldGroupName
                backgroundColour
                screens {
                  image {
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: DOMINANT_COLOR
                          layout: FULL_WIDTH
                        )
                      }
                    }
                    altText
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TwoImagesWithText {
                fieldGroupName
                padding {
                  paddingBottomMobile
                  paddingTopMobile
                }
                disableParallaxScroll
                backgroundColour
                content
                layout
                largeVideo
                largeImage {
                  altText
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                }
                smallVideo
                smallImage {
                  altText
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_List {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                backgroundColour
                heading
                list {
                  hoverImage {
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                      }
                    }
                    altText
                  }
                  name
                  role
                  link
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_AccordionList {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                backgroundColour
                accordionList {
                  content
                  heading
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TwoColumnText {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTopMobile
                  paddingTop
                }
                parallaxEffect
                textLeft
                textRight
                backgroundColour
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_LinkBlock {
                fieldGroupName
                backgroundColour
                links {
                  link {
                    title
                    url
                  }
                  title
                  text
                  text2
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_WorkFeed {
                fieldGroupName
                backgroundColour
                heading
                text1
                text2
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_NextPage {
                fieldGroupName
                backgroundColour
                nextPage {
                  ... on WpPage {
                    id
                    title
                    uri
                    featuredImage {
                      node {
                        altText
                        publicUrl
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                  }
                  ... on WpProject {
                    id
                    title
                    uri
                    featuredImage {
                      node {
                        altText
                        publicUrl
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ModulePages;
